import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';

import { api } from '@/api';
import { socketUrl } from '@/env';
import { getLocalToken } from '@/utils';

export const useTestPlanStore = defineStore('testPlan', {
  state: () => ({
    authToken: null,
    testPlans: [],
    runnerReleaseInfo: null,
    availableTestPlans: [],
    currentTestPlan: null,
    currentLimitSettings: null, 
    currentRunnerSettings: null,
    currentTestPlanConfig: null,
    clientId: null,
    startedBy: null,
    socket_id: null,
    socket: null,
    switchRunnerErrorFlag: false,
    currentSlotInfo: [
      {
        /* Name of the DUT Slot */
        name: "slot_1",
        /* Slot ID, used in log fails and GUI */
        slot_id: "1.1",
        /* Serial Number of the DUT */
        serial_number: "",
        /* Banner Status Text */
        banner_status: "LOADING",
        /* Banner Color */
        banner_color: "#BDBDBD",
        /* Runner Status */
        runner_status: "RUNNING",
        /* Test Run ID */
        run_id: null,
        /* Test Runner Celery Task ID */
        task_id: null,
        /* Show Dialog Flag, used by TestingView and BarcodeScanner */
        show_dialog: false,
        /* Dialogs */
        dialogs: [
          {"title":"ACTION REQUIRED:","message":"Enter serial number","okButtonText":"OK","cancelButtonText":"Cancel","inputType":"text","defaultText":""}
        ],
        /* Notifications */
        notifs: [],
         /* Results */
        results: [],
        /* Test Run Start Time */
        startTime: null,
        /* Test Run End Time */
        endTime: null,
        /* Websocket Client ID */
        socket_id: null,
        /* Websocket Connection */
        socket: null,
      }
    ],
    currentRunnerState: {
      /** Test Plan in Use */
      currentTestPlan: null,
      /** Test Running Flag */
      runTests: false,
      /** Test Cancelled Flag */
      cancelTests: false,
      /** Test Environment Loaded Flag */
      envLoaded: false,
      /** Test Environment Celery Task ID */
      envTaskId: null,
      /** Task Polling Interval */
      taskPollInterval: null,
    },
    bannerColors: {
      "RUNNING": "#64B5F6",
      "PASSED": "#66bb6a",
      "FAILED": "#bb6666",
      "CANCELED": "#FF9800",
      "CANCELING": "#FBC02D",
      "IDLE": "#BDBDBD",
    }
  }),
  getters: {
    getTestPlans: (state) => {
      return state.testPlans;
    },
    getRunnerReleaseInfo: (state) => {
      return state.runnerReleaseInfo;
    },
    getAvailableTestPlans: (state) => {
      return state.availableTestPlans;
    },
    getCurrentTestPlan: (state) => {
      return state.currentTestPlan;
    },
    getCurrentLimitSettings: (state) => {
      return state.currentLimitSettings;
    },
    getCurrentRunnerSettings: (state) => {
      return state.currentRunnerSettings;
    },
    getCurrentFixtureSettings: (state) => {
      return state.currentTestPlan.fixture_settings;
    },
    getCurrentTestPlanConfig: (state) => {
      return state.currentTestPlanConfig;
    },
    getCurrentRunnerState: (state) => {
      return state.currentRunnerState;
    },
    getTestPlan: (state) => (testPlanId) => {
      return state.testPlans.find(testPlan => testPlan.id === testPlanId);
    },

    /* Runner State Settings */
    getTestEnvLoaded: (state) => {
      return state.currentRunnerState.envLoaded;
    },
    getTestRunning: (state) => {
      return state.currentRunnerState.runTests;
    },
    getTestCancelled: (state) => {
      return state.currentRunnerState.cancelTests;
    },
    getRunnerControl: (state) => {
      return state.startedBy == state.clientId;
    },
    getSlotInfo: (state) => (index) => {
      return state.currentSlotInfo[index];
    },

    /* Slot Settings */
    getSlotDialogsAndNotifs: (state) => (index) => {
      const slot = state.currentSlotInfo[index];
      if (!slot) {
        return [];
      }

      // Combine dialogs and notifications
      return [...slot.dialogs, ...slot.notifs];
    },
    getSlotDialogFlag: (state) => (index) => {
      const slot = state.currentSlotInfo[index];
      if (!slot) {
        return false;
      }
      return slot.show_dialog;
    }
  },
  actions: {
    reset() {
      this.$reset();
    },
    async fetchTestPlan(planId) {
      /**
       * Fetch the specified test plan
       */
      try {
        if (!this.authToken) {
          this.authToken = getLocalToken()
        }
        const response = await api.getPlan(this.authToken, planId);
        this.testPlan = response.data;
        return this.testPlan;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchTestPlans() {
      /**
       * Fetch the test plans for the current user
       */
      try {
        if (!this.authToken) {
          this.authToken = getLocalToken()
        }
        const response = await api.getTestPlans(this.authToken);
        this.testPlans = response.data;
        return this.testPlans;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchRunnerReleaseInfo() {
      /**
       * Fetch the runner release info
       */
      try {
        if (!this.authToken) {
          this.authToken = getLocalToken()
        }
        console.log("sending API request, " + this.authToken)
        const response = await api.getRunnerReleaseInfo(this.authToken);
        this.runnerReleaseInfo = response.data;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchRunnerState() {
      /**
       * Fetch the runner release info
       */
      try {
        if (!this.authToken) {
          this.authToken = getLocalToken()
        }
        var response = await api.getRunnerState(this.authToken, 1);
        var runner_state = response.data;
        var active_frontend_runner_state = runner_state.active_frontend_runner_state;
        console.log(runner_state.active_frontend_slot_info)
        
        // Make sure backend state wasn't saved incorrectly:
        console.log("test-plan-store.fetchRunnerState: Checking runner status ...")
        await this.fetchRunTestsStateFromTasks(runner_state);

        // Load backend runner state to frontend cache
        if ( !this.currentRunnerSettings.runTests && active_frontend_runner_state.runTests && runner_state.active_frontend_client_id != this.clientId ) {
          this.startedBy = runner_state.active_frontend_client_id;
          if (runner_state.active_plan_id != this.currentTestPlan.id) {
            console.error("test-plan-store.fetchRunnerState: Different Test Plan Running", runner_state.active_plan_id, this.currentTestPlan.id)
            this.currentRunnerState.currentTestPlan = runner_state.active_plan_id
            this.switchRunnerErrorFlag = "Another device is running a different test plan. Switching now may cause unexpected errors to occur!"
          }
          console.log("test-plan-store.fetchRunnerState: Loading Test Slots...", runner_state.active_frontend_slot_info, runner_state.active_frontend_slot_info.length)

          let savedData;
          for (let i = 0; i < runner_state.active_frontend_slot_info.length; i++) {
            savedData = {
              "show_dialog": this.currentSlotInfo[i].show_dialog,
              "dialogs": this.currentSlotInfo[i].dialogs
            }
            this.currentSlotInfo[i] = runner_state.active_frontend_slot_info[i];
            Object.assign(this.currentSlotInfo[i], savedData)
          }


          savedData = {
            "taskPollInterval": this.currentRunnerState.taskPollInterval
          }
          this.currentRunnerState = active_frontend_runner_state;
          Object.assign(this.currentRunnerState, savedData)

          await this.initTestRun();
          await this.loadTestAllSlots();
        }

      return active_frontend_runner_state.runTests

      } catch (err) {
        console.log(err);
      }
    },
    async updateRunnerState() {
      try {
        if (!this.authToken) {
          this.authToken = getLocalToken()
        }
        if (this.startedBy == this.clientId){
          let runner_state = {
            "active_frontend_client_id": this.clientId,
            "active_plan_id": this.currentTestPlan.id,
            "active_frontend_slot_info": this.currentSlotInfo,
            "active_frontend_runner_state": this.currentRunnerState,
          };
          console.log("test-plan-store.updateRunnerState: Updating Runner State ", runner_state)
          await api.updateRunnerState(this.authToken, 1, runner_state);

        }

      } catch (err) {
        console.error(err);
      }

    },
    async switchRunnerControl() {
      try {
        if (!this.authToken) {
          this.authToken = getLocalToken()
        }

        if (this.currentRunnerState.currentTestPlan != this.currentTestPlan.id) {
          console.error("test-plan-store.switchRunnerControl: Different Test Plan Running", this.currentRunnerState.currentTestPlan, this.currentTestPlan.id)
        }
        this.startedBy = this.clientId;
        this.updateRunnerState();

        this.sendRunnerSocketMsg("sync", "frontend") 
      } catch (err) {
        console.error(err);
      }
    },
    async fetchAvailableTestPlans() {
      /**
       * Fetch the available test plans for the current user
       */
      try {
        if (!this.authToken) {
          this.authToken = getLocalToken()
        }
        const response = await api.findTestPlans(this.authToken);
        this.availableTestPlans = response.data;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchLimitSettings(planPath) {
      /**
       * Fetch the runner test plan limit settings
       */
      try {
        if (!this.authToken) {
          this.authToken = getLocalToken()
        }
        const response = await api.getTestPlanLimitSettings(this.authToken, planPath);
        return this.currentLimitSettings = response.data;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchTestPlanRunnerSettings(planPath) {
      /**
       * Fetch the test plan runner settings for the given test plan path
       *
       * @param {string} planPath - The test plan path
       */
      try {
        if (!this.authToken) {
          this.authToken = getLocalToken()
        }
        const response = await api.getTestPlanRunnerSettings(this.authToken, planPath);
        return this.currentRunnerSettings = response.data;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchTestPlanConfig(planPath) {
      /**
       * Fetch the test plan config for the given test plan path
       *
       * @param {string} planPath - The test plan path
       */
      try {
        if (!this.authToken) {
          this.authToken = getLocalToken()
        }
        const response = await api.getTestPlanConfig(this.authToken, planPath);
        return this.currentTestPlanConfig = response.data;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchCurrentTestPlan() {
      /**
       * Fetch the selected test plan for the current user and load the slot settings.
       *
       * This method is called when the user logs in and when the user changes the selected test plan.
       * The slot settings are loaded and used to set the GUI state for each slot and store any messages
       * or notifications for the user.
       */
      try {
        if (!this.authToken) {
          this.authToken = getLocalToken()
        }
        const response = await api.getSelectedPlan(this.authToken);

        this.currentTestPlan = response.data
        this.currentRunnerSettings = this.currentTestPlan.runner_settings

        // Load the slot settings
        this.currentSlotInfo = [];
        for (let key in this.currentTestPlan.fixture_settings) {
          let slotSettings = this.currentTestPlan.fixture_settings[key];
          if (slotSettings) {
            let slotSettingsObj = {
              name: key,
              slot_id: slotSettings.slot_id,
              serial_number: "",
              banner_status: "LOADING",
              banner_color: this.bannerColors["RUNNING"],
              runner_status: "RUNNING",
              run_id: null,
              task_id: null,
              show_dialog: false,
              dialogs: [],
              notifs: [],
              results: [],
              start_time: null,
              socket: null,
            };
            this.currentSlotInfo.push(slotSettingsObj);
          }
        }

        console.log("test-plan-store.fetchCurrentTestPlan: currentSlotInfo: ", this.currentSlotInfo);
        
        return this.currentTestPlan = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async addTestPlan(testPlan) {
      /**
       * Add a new test plan
       *
       * @param {object} testPlan - The test plan to add
       */
      try {
        if (!this.authToken) {
          this.authToken = getLocalToken()
        }
        await api.createTestPlan(this.authToken, testPlan);
        await this.fetchTestPlans(this.authToken);
      } catch (err) {
        console.log(err);
      }
    },
    async updateTestPlan(testPlanId, testPlan) {
      /**
       * Update existing test plan
       *
       * @param {object} testPlan - The test plan to add
       */
      try {
        if (!this.authToken) {
          this.authToken = getLocalToken()
        }
        await api.updateTestPlan(this.authToken, testPlanId, testPlan);
        await this.fetchTestPlans(this.authToken);
      } catch (err) {
        console.log(err);
      }
    },
    async deleteTestPlan(testPlanId) {
      /**
       * Delete a test plan
       *
       * @param {number} testPlanId - The test plan id to delete
       */
      try {
        if (!this.authToken) {
          this.authToken = getLocalToken()
        }
        await api.deleteTestPlan(this.authToken, testPlanId);
        await this.fetchTestPlans(this.authToken);
      } catch (err) {
        console.log(err);
      }
    },
    getDutIdx(x, y) {
      /**
       * Calculate the DUT Slot Settings Index from the Grid Settings
       *
       * @param {number} x - The x coordinate of the DUT Slot
       * @param {number} y - The y coordinate of the DUT Slot
       * @returns {number} - The DUT Slot Settings Index
       * */
      return (y - 1) + (x - 1) * this.currentTestPlan.runner_settings.dut_grid[1];
    },

    async getSlotInfoXY(x, y) {
      /**
       * Get the Slot Settings for the given DUT Slot Index
       *
       * @param {number} x - The x coordinate of the DUT Slot
       * @param {number} y - The y coordinate of the DUT Slot
       * @returns {object} - The Slot Settings for the given DUT Slot
       * */
      let dut_idx = this.getDutIdx(x, y);
      console.log("test-plan-store.getSlotInfo: dut_idx: ", dut_idx);
      console.log(this.currentSlotInfo[dut_idx]);
      return this.currentSlotInfo[dut_idx];
    },
    async updateSlotSettings(x, y, slotSettings) {
      /**
       * Update the Slot Settings for the given DUT Slot Index
       *
       * @param {number} x - The x coordinate of the DUT Slot
       * @param {number} y - The y coordinate of the DUT Slot
       * @param {object} slotSettings - The Slot Settings for the given DUT Slot
       */
      let dut_idx = this.getDutIdx(x, y);
      this.currentRunnerSettings.fixture_settings[dut_idx] = slotSettings;
    },
    async setAllSlotsState(bannerMsg, runnerStatus) {
      /**
       * Update all the Slot Settings
       *
       * @param {string} bannerMsg - The banner message
       * @param {string} bannerColor - The banner color
       */
      for (let i = 0; i < this.currentSlotInfo.length; i++) {
        this.currentSlotInfo[i].banner_status = bannerMsg;
        this.currentSlotInfo[i].banner_color = this.bannerColors[runnerStatus];
        this.currentSlotInfo[i].runner_status = runnerStatus;
      }
      console.log("test-plan-store.setAllSlotsBanner: currentSlotInfo: ", this.currentSlotInfo);
    },
    async setSlotStateIdx(dutIdx, bannerMsg, runnerStatus) {
      /**
       * Update the Slot Settings for the given DUT Slot Index
       *
       * @param {number} dutIdx - The DUT Slot Index
       * @param {string} bannerMsg - The banner message
       * @param {string} bannerColor - The banner color
       */
      this.currentSlotInfo[dutIdx].banner_status = bannerMsg;
      this.currentSlotInfo[dutIdx].banner_color = this.bannerColors[runnerStatus];
      this.currentSlotInfo[dutIdx].runner_status = runnerStatus;
    },
    async setSlotDialogFlag(dutIdx, flag) {
      /**
       * Set the show dialog flag for the given DUT Slot Index
       *
       * @param {number} dutIdx - The DUT Slot Index
       * @param {boolean} flag - The show dialog flag
       */
      this.currentSlotInfo[dutIdx].show_dialog = flag;
    },
    async initTestPlanEnv() {
      /**
       * Initialize the Python Environment for the currently selected test plan
       */
      console.log("test-plan-store.initSlotEnv: -> start prestart task");
      try {

        // Retrieve auth token if not already set
        if (!this.authToken) {
          this.authToken = getLocalToken()
        }

        // Send API request to install the test plans python environment
        const response = await api.prestartTest(
          this.authToken,
          this.currentTestPlan.id,
          null,
          null
        );
        console.log("test-plan-store.initSlotEnv: response: ", response);

        // Store the Celery Task ID for the prestart task, this will be used to check the status of the task
        this.currentRunnerState.envTaskId = response.data.task_id;
        return true;
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    async checkTaskStatus(taskId) {
      /**
       * Check the status of the given Celery Task ID
       *
       * @param {string} taskId - The Celery Task ID
       */
      console.log("test-plan-store.checkTaskStatus: -> check task status, taskId: ", taskId);

      // If no task ID is given, return IDLE
      let response = {
        state: "IDLE",
        result: "No task running",
      }
      if (taskId) {
        // Get task status
        try {
          if (!this.authToken) {
            this.authToken = getLocalToken()
          }
          const task_status = await api.getTaskStatus(this.authToken, taskId);
          response = task_status.data;

        } catch (err) {
          console.log(err);
          response = {
            state: "FAILURE",
            result: "Task failed, err =" + err,
          }
        }
      }
      console.log("test-plan-store.checkTaskStatus: response: ", response);
      return response;
    },
    async checkInitTestPlanEnvStatus() {
      /**
       * Check the status of the Python Environment initialization task.
       */
      console.log("test-plan-store.checkInitTestPlanEnvStatus: -> check task status");
      const response = await this.checkTaskStatus(this.currentRunnerState.envTaskId);

      // Check task status
      if (response.state === "SUCCESS") {
        console.log("test-plan-store.checkInitTestPlanEnvStatus: environment init successful")
        this.currentRunnerState.envLoaded = true;
        this.currentRunnerState.envTaskId = null;
      }
      if (response.state === "FAILURE") {
        console.log("test-plan-store.checkInitTestPlanEnvStatus: environment init failed")
        this.currentRunnerState.envLoaded = false;
        this.currentRunnerState.envTaskId = null;
      }
      // Return task result
      return response;
    },
    async initCurrentRunnerState() {
      /**
       * Initialize the current runner state
       */
      this.currentRunnerState.runTests = false;
      this.currentRunnerState.cancelTests = false;
      this.currentRunnerState.envLoaded = false;
      this.currentRunnerState.envTaskId = null;
    },

    /* Test Run Management Functions */
    async initTestRun() {
      /**
       * Initialize the test run GUI states
       */
      console.log("test-plan-store.initTestRun: -> init test run");
      for (let i = 0; i < this.currentSlotInfo.length; i++) {

        // If the slot is already running, skip it
        if (this.currentSlotInfo[i].runner_status === "RUNNING") {
          continue
        }

        // Clear any currently waiting dialogs and notifications and reset state
        this.currentSlotInfo[i].banner_status = "READY";
        this.currentSlotInfo[i].banner_color = this.bannerColors["IDLE"];
        this.currentSlotInfo[i].runner_status = "IDLE";
        this.currentSlotInfo[i].run_id = null;
        this.currentSlotInfo[i].task_id = null;
        this.currentSlotInfo[i].dialogs = [];
        this.currentSlotInfo[i].notifs = [];
        this.currentSlotInfo[i].results = [];
        this.currentSlotInfo[i].startTime = null;
        this.currentSlotInfo[i].socket = null;
      }
    },
    async loadTest(slotInfo){
      /**
       * Set the test running state and start tests for the given slot
       *
       * @param {object} slotInfo - The Slot Settings for the given DUT Slot
       */
      console.log("test-plan-store.loadTest: Connecting to running test for slot: ", slotInfo.name);

      // Check if the test environment is loaded
      if (!this.currentRunnerState.envLoaded) {
        console.log("test-plan-store.loadTest: environment not loaded, reload page");
        return false;
      }

      if (slotInfo.startTime) {
        slotInfo.startTime = Date.parse(slotInfo.startTime);
      }
      try {
        // Open a websocket connection for interacting with the test runner
        await this.initSlotSocket(slotInfo);

        // Start polling for task status if not already running
        if (!this.currentRunnerState.taskPollInterval) {
          this.currentRunnerState.taskPollInterval = setInterval(
            () => this.checkTestRunStatus(),
            1000
          );
        }
        return true;
      } catch (error) {
        console.log(error);
        slotInfo.banner_status = "FAILED TO START TEST";
        slotInfo.banner_color = this.bannerColors["FAILED"];
        slotInfo.runner_status = "FAILED";
        return false;
      }

    },
    async startTest(slotInfo) {
      /**
       * Set the test running state and start tests for the given slot
       *
       * @param {object} slotInfo - The Slot Settings for the given DUT Slot
       */

      // Check if the test environment is loaded
      if (!this.currentRunnerState.envLoaded) {
        console.log("test-plan-store.startTest: environment not loaded, reload page");
        return false;
      }
      console.log("test-plan-store.startTest: Starting test for slot: ", slotInfo.name);
      await this.initTestRun();
      slotInfo.banner_status = "RUNNING";
      slotInfo.banner_color = this.bannerColors["RUNNING"];
      slotInfo.runner_status = "RUNNING";
      slotInfo.startTime = new Date();
      slotInfo.endTime = null;

      try {
        // Open a websocket connection for interacting with the test runner
        await this.initSlotSocket(slotInfo);

        const response = await api.runTest(
          this.authToken,
          this.currentTestPlan.id,
          slotInfo.socket_id,
          null,
          slotInfo.slot_id
        );
        console.log("test-plan-store.startTest: response: ", response);

        // Set the run ID and task ID
        slotInfo.run_id = response.data.run_id;
        slotInfo.task_id = response.data.task_id;

        await this.updateRunnerState()

        const runner_state = {
          "active_frontend_client_id": this.clientId,
          "active_plan_id": this.currentTestPlan.id,
          "active_frontend_slot_info": this.currentSlotInfo,
          "active_frontend_runner_state": this.currentRunnerState,
        };
        this.sendSocketMsg(slotInfo, "starting", "frontend", runner_state)

        // Start polling for task status if not already running
        if (!this.currentRunnerState.taskPollInterval) {
          this.currentRunnerState.taskPollInterval = setInterval(
            () => this.checkTestRunStatus(),
            1000
          );
        }
        return true;
      } catch (error) {
        console.log(error);
        slotInfo.banner_status = "FAILED TO START TEST";
        slotInfo.banner_color = this.bannerColors["FAILED"];
        slotInfo.runner_status = "FAILED";
        return false;
      }
    },
    async cancelTest(slotInfo) {
      /**
       * Set the test cancelled state and terminate running tasks for the given slot
       */
      console.log("test-plan-store.cancelTest: Cancelling test for slot: ", slotInfo.name);
      slotInfo.banner_status = "CANCELING";
      slotInfo.banner_color = this.bannerColors["CANCELING"];
      slotInfo.runner_status = "CANCELING";

      try {
        await api.cancelTest(this.authToken, slotInfo.task_id);
        await api.teardownTest(this.authToken, slotInfo.run_id, this.currentTestPlan.id, slotInfo.socket_id);
        slotInfo.socket.close();

        // Clear any currently waiting dialogs and notifications
        slotInfo.dialogs = [];
        slotInfo.notifs = [];
      } catch (error) {
        console.log(error);

      }
      slotInfo.banner_status = "CANCELED";
      slotInfo.banner_color = this.bannerColors["CANCELED"];
      slotInfo.runner_status = "CANCELED";
    },
    async startTestSlotIdx(dutIdx) {
      /**
       * Set the test running state and start tests for the given slot
       *
       * @param {number} dutIdx - The DUT Slot Index
       */


      console.log("test-plan-store.startTestSlot: Starting test for slot: ", dutIdx);
      return await this.startTest(this.currentSlotInfo[dutIdx]);
    },
    async cancelTestSlotIdx(dutIdx) {
      /**
       * Set the test cancelled state and cancel tests for the given slot
       */
      console.log("test-plan-store.cancelTestSlot: Cancelling test for slot: ", dutIdx);
      await this.cancelTest(this.currentSlotInfo[dutIdx]);
    },
    async loadTestAllSlots() {
      /**
       * Load the test running state for all slots
       *
       */
      console.log(this.currentRunnerState)
      this.currentRunnerState.runTests = true;

      let status = false;
      for (let i = 0; i < this.currentSlotInfo.length; i++) {
        status = await this.loadTest(this.currentSlotInfo[i]);
        if (!status) {
          break;
        }
      }
      return status;
    },
    async startTestAllSlots() {
      /**
       * Set the test running state and start tests for all slots
       *
       */
      await this.fetchRunnerState();
      if (this.currentRunnerState.runTests) {
        return;
      }

      this.currentRunnerState.runTests = true
      this.startedBy = this.clientId

      let status = false;
      for (let i = 0; i < this.currentSlotInfo.length; i++) {
        status = await this.startTest(this.currentSlotInfo[i]);
        if (!status) {
          break;
        }
      }
      return status;
    },
    async cancelTestAllSlots() {
      /**
       * Set the test cancelled state and cancel tests for all slots
       *
       */
      this.currentRunnerState.cancelTests = true;

      // Cancel all running tests
      await Promise.all(this.currentSlotInfo.map(slotInfo => this.cancelTest(slotInfo)));

      this.currentRunnerState.runTests = false;
      this.updateRunnerState();
    },
    async updateDuration(dutIdx){
      /**
       * Check the status of the test run for the given slot
       *
       * @param {number} dutIdx - The DUT Slot Index
       */
      console.log("test-plan-store.updateDuration: -> update test duration");

      const slotInfo = this.currentSlotInfo[dutIdx];
      slotInfo.endTime = new Date();

      const duration = (slotInfo.endTime - slotInfo.startTime) / 1000;
      console.log("test-plan-store.updateDuration: -> sending duration to backend: ", duration);
      
      const response = await api.updateRun(this.authToken, slotInfo.run_id, {"duration": duration});
      console.log("test-plan-store.updateDuration: -> updated run: ", response);

      return response;
    },
    async checkTestRunStatusSlotIdx(dutIdx) {
      /**
       * Check the status of the test run for the given slot
       *
       * @param {number} dutIdx - The DUT Slot Index
       */
      console.log("test-plan-store.checkTestRunStatus: -> check test run status, dutIdx: ", dutIdx);
      const slotInfo = this.currentSlotInfo[dutIdx];
      console.log("test-plan-store.checkTestRunStatus: slotInfo: ", slotInfo);
      const response = await this.checkTaskStatus(slotInfo.task_id);

      // Check task status
      if (response.state === "SUCCESS") {
        console.log("test-plan-store.checkTestRunStatus: test run successful")
        slotInfo.banner_status = "PASSED";
        slotInfo.banner_color = this.bannerColors["PASSED"];
        slotInfo.runner_status = "PASSED";
        this.updateDuration(dutIdx);
      } else if (response.state === "FAILURE") {
        console.log("test-plan-store.checkTestRunStatus: test run failed")
        slotInfo.banner_status = "FAILED";
        slotInfo.banner_color = this.bannerColors["FAILED"];
        slotInfo.runner_status = "FAILED";
        this.updateDuration(dutIdx);
      } else if (response.state === "REVOKED") {
        console.log("test-plan-store.checkTestRunStatus: test run cancelled")
        slotInfo.banner_status = "CANCELED";
        slotInfo.banner_color = this.bannerColors["CANCELED"];
        slotInfo.runner_status = "CANCELED";
        this.updateDuration(dutIdx);
      }

      // Return task result
      return response;
    },
    async checkTestRunStatus() {
      /**
       * Check the status of the test run for all slots
       *
       */
      console.log("test-plan-store.checkTestRunStatus: -> check test run status");
      let numFinished = 0;
      for (let i = 0; i < this.currentSlotInfo.length; i++) {
        let response = await this.checkTestRunStatusSlotIdx(i);

        if (response.state !== "PENDING") {
          // Re-run automatically when autorun feature enabled if not cancelled
          // this.sendSocketMsg(this.currentSlotInfo[i], "sync", "frontend") 
          if (this.currentRunnerSettings.autorun && response.state !== "REVOKED") {
            await this.startTest(this.currentSlotInfo[i])
          }
          else {
            numFinished++;
          }
        }
      }
      console.log("test-plan-store.checkTestRunStatus: numFinished: ", numFinished, this.currentSlotInfo.length);
      this.currentRunnerState.runTests = !(numFinished === this.currentSlotInfo.length);

      await this.updateRunnerState();

      // If all tasks are finished, stop polling for status
      if (numFinished >= this.currentSlotInfo.length) {
        console.log("test-plan-store.checkTestRunStatus: all tasks finished, clearing interval")
        this.currentRunnerState.runTests = false;
        await this.updateRunnerState();

        this.startedBy = null;
        clearInterval(this.currentRunnerState.taskPollInterval);
        this.currentRunnerState.taskPollInterval = null;
      }
    },

    async fetchRunTestsStateFromTasks(runner_data) {
      /**
       * Check the status of the test run from backend runner_data
       *
       */
      console.log("test-plan-store.fetchRunTestsStateFromTasks: -> check test run status");
      var currentSlotInfo = runner_data.active_frontend_slot_info
      let numRunning = 0;
      for (let i = 0; i < currentSlotInfo.length; i++) {
        const response = await this.checkTaskStatus(currentSlotInfo[i].task_id);

        if (response.state == "PENDING") {
            numRunning++;
        }
      }
      console.log("test-plan-store.fetchRunTestsStateFromTasks: numRunning: ", numRunning);
      this.currentRunnerState.runTests = numRunning > 0;
    },
    /* Test Runner Socket Management Functions */
    async initSlotSockets() {
      /**
       * Set the test running state and start tests for all slots
       *
       */

      let status = false;
      for (let i = 0; i < this.currentSlotInfo.length; i++) {
        status = await this.initSlotSocket(this.currentSlotInfo[i]);
        if (!status) {
          break;
        }
      }
      return status;
    },
    async initSocket() {
      /**
       * Initialize the socket for main test runner
       */

      if (!this.clientId) {
        this.clientId = uuidv4();
        console.log("test-plan-store.initSocket: Setting Client ID...", this.clientId)
      }

      this.socket_id = this.clientId + "_frontend_runner"
      console.log("test-plan-store.initSocket: -> init socket, socket id:", this.socket_id);
        
      // Close socket if one is already open
      if (this.socket) {
        console.log("test-plan-store.initSocket: checking socket status, slot = ", this.socket_id, " status = ", this.socket.readyState);

        if (!this.socket.readyState !== WebSocket.OPEN){
          console.log("test-plan-store.initSocket: socket already connected");
          return;
        }
      }
      // Create new socket connection
      console.log("test-plan-store.initSocket: creating new socket, slot = ", this.socket_id);
      this.socket = new WebSocket(
        `${socketUrl}/api/v1/utils/runner_socket/` + this.socket_id
      );
      this.socket.onmessage = (event) => {
        this.handleSocketMsg(event, null, this.socket_id);
      };
      this.socket.onopen = (event) => {
        this.handleSocketOpen(event, this.socket_id);
      };
      console.log("test-plan-store.initSocket: -> socket created");
    },
    async initSlotSocket(slotInfo) {
      /**
       * Initialize the socket for the given DUT Slot Index
       */

      slotInfo.socket_id = this.clientId + "_frontend_" + slotInfo.slot_id;
      console.log("test-plan-store.initSlotSocket: -> init slot socket, slot:", slotInfo.name, "socket id:", slotInfo.socket_id);
        
      // Close socket if one is already open
      if (slotInfo.socket) {
        console.log("test-plan-store.initSlotSocket: closing existing socket, socket:", slotInfo.socket);
        slotInfo.socket.close();
      }

      // Create new socket connection
      console.log("test-plan-store.initSlotSocket: creating new socket, slot = ", slotInfo.slot_id);
      slotInfo.socket = new WebSocket(
        `${socketUrl}/api/v1/utils/runner_socket/` + slotInfo.socket_id
      );
      slotInfo.socket.onmessage = (event) => {
        this.handleSocketMsg(event, slotInfo, slotInfo.socket_id);
      };
      slotInfo.socket.onopen = (event) => {
        this.handleSocketOpen(event, slotInfo.socket_id);
      };
      console.log("test-plan-store.initSlotSocket: -> socket created");
    },
    async handleSocketOpen(event, socket_id) {
      /**
       * Handle socket open event
       */
      console.log("test-plan-store.handleSocketOpen: -> socket opened: ", socket_id);
    },
    async handleSocketMsg(event, slotInfo, socket_id) {
      /**
       * Handle messages received from the test runner socket
       */
      console.log("test-plan-store.handleSocketMsg: -> received socket message: ", socket_id);
      const msg = JSON.parse(event.data)
      console.log("test-plan-store.handleSocketMsg: ", msg.toClient, " msg: ", msg);
      if (!msg.fromClient.includes(this.clientId)){
        if(msg.type === "connect") {
          if (msg.fromClient.includes(slotInfo.run_id)) {
            await this.sendSocketMsg(slotInfo, "connect", msg.fromClient, "");
          }
        } else if (msg.type === "starting") {
          await this.handleAsyncStartTest(msg.data);
        } else if (msg.type === "sync") {
          await this.fetchRunnerState();
        } else if (msg.type === "banner") {
          await this.handleBanner(slotInfo, msg.data);
        } else if (msg.type === "dialog") {
          await this.handleDialog(slotInfo, msg.data);
        } else if (msg.type === "notif") {
          await this.handleNotif(slotInfo, msg.data);
        } else if (msg.type === "result") {
          await this.handleResult(slotInfo, msg.data);
        } else if (msg.type === "serial") {
          await this.handleSerial(slotInfo, msg.data);
        }

      }
    },
    async sendSocketMsg(slotInfo, type, toClient, data) {
      /**
       * Send a message to the test runner socket
       */
      console.log("test-plan-store.sendSocketMsg: -> sending socket message, slot: ", slotInfo.slot_id);
      const response = {
        type: type,
        toClient: toClient,
        fromClient: slotInfo.socket_id,
        data: data
      }
      console.log("test-plan-store.sendSocketMsg: ", response);
      slotInfo.socket.send(JSON.stringify(response));
    },
    async sendRunnerSocketMsg(type, toClient, data) {
      /**
       * Send a message to the test runner socket
       */
      console.log("test-plan-store.sendSocketMsg: -> sending socket message, slot: ", this.socket_id);
      const response = {
        type: type,
        toClient: toClient,
        fromClient: this.socket_id,
        data: data
      }
      console.log("test-plan-store.sendSocketMsg: ", response);
      this.socket.send(JSON.stringify(response));
    },

    /* Websocket Message Handlers */
    async handleAsyncStartTest(runner_data) {
      /**
       * Handle a start test message from another frontend client
       */
      console.log("test-plan-store.handleAsyncStartTest: -> handling start message,", runner_data);

      if (!this.currentRunnerState.runTests) {
        this.startedBy = runner_data.active_frontend_client_id;
        await this.initTestRun();
        await this.fetchRunnerState();
        await this.loadTestAllSlots();
      }
    },
    async handleBanner(slotInfo, result) {
      /**
       * Handle a banner message from the test runner socket
       */
      console.log("test-plan-store.handleBanner: -> handling banner message, slot: ", slotInfo.slot_id);
      console.log("test-plan-store.handleBanner: -> result: ", result)
      if (result) {
        console.log("test-plan-store.handleBanner: -> setting custom banner")
        slotInfo.banner_status = result.status;
        slotInfo.banner_color = result.color;
      } else {
        console.log("test-plan-store.handleBanner: -> restoring default banner")
        slotInfo.banner_status = slotInfo.runner_status;
        slotInfo.banner_color = this.bannerColors[slotInfo.runner_status];
      }
    },
    async handleDialog(slotInfo, result) {
      /**
       * Handle a dialog message from the test runner socket
       */
      console.log("test-plan-store.handleDialog: -> handling dialog message, slot: ", slotInfo.slot_id);
      console.log("test-plan-store.handleDialog: -> result: ", result)
      slotInfo.dialogs.push(result);
    },
    async handleNotif(slotInfo, result) {
      /**
       * Handle a notification message from the test runner socket
       */
      console.log("test-plan-store.handleNotif: -> handling notification message, slot: ", slotInfo.slot_id);
      console.log("test-plan-store.handleNotif: -> result: ", result)
      slotInfo.notifs.push(result);
    },
    async handleResult(slotInfo, result) {
      /**
       * Handle a result message from the test runner socket
       */
      console.log("test-plan-store.handleResult: -> handling result message, slot: ", slotInfo.slot_id);
      if (!result.passed) {
        result.color = this.bannerColors["FAILED"];
      }
      console.log("test-plan-store.handleResult: -> result: ", result)
      slotInfo.results.push(result);
      console.log(slotInfo.results)
    },
    async handleSerial(slotInfo, result) {
      /**
       * Handle a serial message from the test runner socket
       */
      console.log("test-plan-store.handleSerial: -> handling serial message, slot: ", slotInfo.slot_id);
      console.log("test-plan-store.handleSerial: -> result: ", result)
      slotInfo.serial_number = result.serial_number;
    }
  }
});