import axios from 'axios';
import { apiUrl } from "@/env";

function authHeaders(token) {
    return {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
    };
}

function downloadHeaders(token, paramInputs) {
    return {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
        withCredentials: true,
        params: paramInputs,
    };
}

function params(token, paramInputs) {
    return {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
        params: paramInputs,
    };
}

export const api = {
    // User API Endpoints
    async loginToken(username, password) {
        let params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);
        console.log("API URL: ", apiUrl);
        return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
    },
    async getMe(token) {
        return axios.get(`${apiUrl}/api/v1/users/me`, authHeaders(token));
    },
    async updateMe(token, data) {
        return axios.put(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
    },
    async getUsers(token) {
        return axios.get(`${apiUrl}/api/v1/users/`, authHeaders(token));
    },
    async createUser(token, data) {
        return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
    },
    async getUser(token, userId) {
        return axios.get(`${apiUrl}/api/v1/users/${userId}`, authHeaders(token));
    },
    async updateUser(token, userId, data) {
        console.log("updateUser: updating user profile, payload:", data)
        return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
    },
    async deleteUser(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/users/${userId}`, authHeaders(token));
    },

    // Role API Endpoints
    async getRoles(token) {
        return axios.get(`${apiUrl}/api/v1/roles/`, authHeaders(token));
    },

    async getRunnerState(token, id) {
        return axios.get(`${apiUrl}/api/v1/runner/state/${id}`, authHeaders(token));
    },
    async updateRunnerState(token, id, data) {
        console.log("updateUser: updating user profile, payload:", data)
        return axios.post(`${apiUrl}/api/v1/runner/state/${id}`, data, authHeaders(token));
    },
    // Test Plan Settings and Runs API Endpoints
    async findTestPlans(token) {
        return axios.get(`${apiUrl}/api/v1/runner/available-plans/`, authHeaders(token));
    },
    async getTestPlanLimitSettings(token, plan_path) {
        let paramInputs = { "plan_path": plan_path }
        return axios.get(`${apiUrl}/api/v1/runner/limits`, params(token, paramInputs));
    },
    async getTestPlanRunnerSettings(token, plan_path) {
        let paramInputs = { "plan_path": plan_path }
        return axios.get(`${apiUrl}/api/v1/runner/settings`, params(token, paramInputs));
    },
    async getTestPlanConfig(token, plan_path) {
        let paramInputs = { "plan_path": plan_path }
        return axios.get(`${apiUrl}/api/v1/runner/test-plan-config`, params(token, paramInputs));
    },
    async getSelectedPlan(token) {
        return axios.get(`${apiUrl}/api/v1/runner/selected-plan/`, authHeaders(token));
    },
    async getTestPlans(token) {
        return axios.get(`${apiUrl}/api/v1/plans/`, authHeaders(token));
    },
    async createTestPlan(token, data) {
        return axios.post(`${apiUrl}/api/v1/plans/`, data, authHeaders(token));
    },
    async updateTestPlan(token, planId, data) {
        return axios.post(`${apiUrl}/api/v1/plans/${planId}`, data, authHeaders(token));
    },
    async deleteTestPlan(token, planId) {
        return axios.delete(`${apiUrl}/api/v1/plans/${planId}`, authHeaders(token));
    },
    async getPlan(token, planId) {
        let paramInputs = {"plan_id": planId}
        return axios.get(`${apiUrl}/api/v1/plans/${planId}`, params(token, paramInputs));
    },
    async getRun(token, runId) {
        let paramInputs = {"id": runId}
        return axios.get(`${apiUrl}/api/v1/runs/${runId}`, params(token, paramInputs));
    },
    async updateRun(token, runId, runData) {
        return axios.post(`${apiUrl}/api/v1/runs/${runId}`, runData, authHeaders(token));
    },
    async deleteRun(token, runId) {
        return axios.delete(`${apiUrl}/api/v1/runs/${runId}`, authHeaders(token));
    },
    async deleteAllRuns(token, planId) {
        let paramInputs = {"plan_id": planId}
        return axios.delete(`${apiUrl}/api/v1/runs/`, params(token, paramInputs));
    },
    async deleteAttachment(token, runId, attachmentId) {
        return axios.delete(`${apiUrl}/api/v1/runs/${runId}/attachment/${attachmentId}`, authHeaders(token));
    },

    async getTotalRuns(token, paramInputs=null) {
        if (paramInputs) {
            return axios.get(`${apiUrl}/api/v1/runs/total`, params(token, paramInputs));
        } else {
            return axios.get(`${apiUrl}/api/v1/runs/total`, authHeaders(token));
        }
    },
    async getRuns(token, paramInputs=null) {
        if (paramInputs) {
            return axios.get(`${apiUrl}/api/v1/runs/`, params(token, paramInputs));
        } else {
            return axios.get(`${apiUrl}/api/v1/runs/`, authHeaders(token));
        }
    },

    // Test Run API Endpoints
    async prestartTest(token, planId) {
        let paramInputs = {"plan_id": planId}
        return axios.post(`${apiUrl}/api/v1/tasks/prestart/`, null, params(token, paramInputs));
    },
    async runTest(token, planId, clientId, url=null, slotId=null) {
        let paramInputs = {"plan_id": planId, "frontend_client_id": clientId}
        if (url) { paramInputs.url = apiUrl }
        if (slotId) { paramInputs.slot_id = slotId }
        return axios.post(`${apiUrl}/api/v1/tasks/start/`, null, params(token, paramInputs));
    },
    async cancelTest(token, taskId) {
        let paramInputs = {"task_id": taskId}
        return axios.post(`${apiUrl}/api/v1/tasks/cancel/`, null, params(token, paramInputs));
    },

    async teardownTest(token, runId, planId, clientId, url=null) {
        const paramInputs = {"plan_id": planId, "run_id": runId, "frontend_client_id": clientId}
        if (url) { paramInputs.url = apiUrl }
        return axios.post(`${apiUrl}/api/v1/tasks/teardown/${runId}`, null, params(token, paramInputs));
    },

    async getTaskStatus(token, taskId) {
        let paramInputs = {"task_id": taskId}
        return axios.get(`${apiUrl}/api/v1/tasks/status/`, params(token, paramInputs));
    },
    async getResults(token, runId) {
        let paramInputs = {"run_id": runId}
        return axios.get(`${apiUrl}/api/v1/runs/${runId}/results/`, params(token, paramInputs));
    },
    async getAttachments(token, runId) {
        let paramInputs = {"run_id": runId}
        return axios.get(`${apiUrl}/api/v1/runs/${runId}/attachments/`, params(token, paramInputs));
    },
    async getAttachment(token, runId, attachementId) {
        return axios.get(`${apiUrl}/api/v1/runs/${runId}/attachment/${attachementId}/download`, authHeaders(token));
    },
    async downloadAttachment(token, runId, attachementId) {
        var paramInputs = {"id": attachementId}
        return axios.get(`${apiUrl}/api/v1/runs/${runId}/attachment/${attachementId}/download`, params(token, paramInputs));
    },
    async downloadSummaryCSV(token, planId, attachments) {
        let paramInputs = {"plan_id": planId};
        console.log("downloadSummaryCSV: -> copying summary csv, params:", paramInputs);
        return axios.post(`${apiUrl}/api/v1/tasks/download-csv/`, attachments, downloadHeaders(token, paramInputs));
    },

    // Utility API Endpoints
    async restart() {
        return axios.get(`${apiUrl}/api/v1/utils/restart/`);
    },
    async reboot() {
        return axios.get(`${apiUrl}/api/v1/utils/reboot/`);
    },
    async getUsbDrives(token) {
        return axios.get(`${apiUrl}/api/v1/tasks/usb-drives/`, authHeaders(token));
    },
    async copyAttachments(token, planId, destination, attachments) {
        let paramInputs = {"destination": destination, "plan_id": planId};
        console.log("copyAttachments: -> copying attachments, params:", paramInputs);
        return axios.post(`${apiUrl}/api/v1/tasks/copy-attachments/`, attachments, params(token, paramInputs));
    },
    async getRunnerReleaseInfo(token) {
        return axios.get(`${apiUrl}/api/v1/utils/sys-info/`, authHeaders(token));
    },
}